import React from 'react';
import './App.scss';
import {BrowserRouter as Router, Route, Switch, useParams} from "react-router-dom";
import {LiveView} from "../views/item/LiveView";
import {LiveListView} from "../views/list/LiveListView";
import {StreamResponseDTO} from "../types/StreamResponseDTO";
import {isStreamListResponseDTO, StreamListResponseDTO} from "../types/StreamListResponseDTO";
import {LIVE_API_URL} from "../../live-app-constants";
import ThemeService, {
    ThemeServiceColorSchemeChangedEventCallback,
    ThemeServiceEvent
} from "../../nor/ui/services/ThemeService";
import {ObserverDestructor} from "../../nor/ts/Observer";
import {ColorScheme} from "../../nor/ui/services/types/ColorScheme";

export interface LiveViewRouteParams {
    readonly name : string
}

export function LiveViewWithParam (props: { darkMode: boolean }) {
    const { darkMode } = props;
    let { name } = useParams<LiveViewRouteParams>();
    const stream : StreamResponseDTO = {
        liveCount: 0,
        maxLiveCount: 0,
        playCount: 0,
        name: name
    };
    return (
        <LiveView
            stream={stream}
            darkMode={darkMode}
        />
    );
}

export interface AppProps {

}

export interface AppState {

    readonly data     : StreamListResponseDTO;
    readonly darkMode : boolean;

}

export class App extends React.Component<AppProps, AppState> {

    private readonly _colorSchemeChangeCallback : ThemeServiceColorSchemeChangedEventCallback;

    private _colorSchemeChangeListener : ObserverDestructor | undefined;

    public constructor (props: AppProps) {

        super(props);

        this.state = {
            data: {
                streams: []
            },
            darkMode: ThemeService.hasDarkMode()
        };

        this._colorSchemeChangeCallback = this._onColorSchemeChange.bind(this);

    }

    componentDidMount() {

        this._refreshData().catch(console.error);

        this._colorSchemeChangeListener = ThemeService.on(ThemeService.Event.COLOR_SCHEME_CHANGED  , this._colorSchemeChangeCallback);

    }

    componentWillUnmount() {

        if (this._colorSchemeChangeListener) {
            this._colorSchemeChangeListener();
            this._colorSchemeChangeListener = undefined;
        }

    }

    private async _refreshData () : Promise<void> {

        try {

            const data : StreamListResponseDTO = await App._loadData();

            this.setState({
                data: data
            });

        } catch (err) {
            console.error(`Error: `, err);
        }

    }

    private static async _loadData () : Promise<StreamListResponseDTO> {

        const response = await fetch(LIVE_API_URL);

        const data : any = await response.json();

        if (isStreamListResponseDTO(data)) {
            return data;
        } else {
            console.error(`Not StreamListResponseDTO: `, data);
            throw new TypeError(`Response was not correct.`);
        }

    }

    public render() {

        const data : StreamListResponseDTO = this.state.data;

        return (
            <Router>
                <Switch>

                    <Route path="/:name">
                        <LiveViewWithParam
                            darkMode={this.state.darkMode}
                        />
                    </Route>

                    <Route path="/">
                        <LiveListView
                            list={data}
                            darkMode={this.state.darkMode}
                        />
                    </Route>

                </Switch>
            </Router>
        );

    }

    private _onColorSchemeChange (event: ThemeServiceEvent, scheme: ColorScheme) {
        this.setState({darkMode: ThemeService.hasDarkMode()});
    }

}

export default App;
