import React from "react";
import {PUBLIC_URL} from "../../live-app-constants";
// import LogService from "../../nor/ts/LogService";
import {ChooseThemeIcon, PlayIcon} from "../../assets/icon";
import ThemeService from "../../nor/ui/services/ThemeService";
import {ColorScheme} from "../../nor/ui/services/types/ColorScheme";
import "./LiveHeader.scss";

// const LOG = LogService.createLogger('LiveHeader');

export interface LiveHeaderChangeColorSchemeCallback {
    (value: ColorScheme): void;
}

export interface LiveHeaderProps {

    readonly title             ?: string;
    readonly icon              ?: any;
    readonly changeColorScheme ?: LiveHeaderChangeColorSchemeCallback;

}

export interface LiveHeaderState {
}

export class LiveHeader extends React.Component<LiveHeaderProps, LiveHeaderState> {

    private readonly _themeSwitchCallback : (event: React.MouseEvent<HTMLButtonElement>) => void;

    public constructor (props: LiveHeaderProps) {

        super(props);

        this.state = {};

        this._themeSwitchCallback = this._onThemeSwitch.bind(this);

    }

    public render() : any {

        const {title, icon} = this.props;

        const Icon = icon ?? PlayIcon;

        return (
            <header className="App-header">

                <div className="App-header-title">
                    <a className="App-header-title-link" href={PUBLIC_URL}><Icon
                        className="App-header-title-icon"
                    /></a>
                    {title ? title : null}
                </div>

                {this.props.children}

                <div className="App-header-options">
                    <button
                        className="App-header-options-button App-header-options-theme-button"
                        onClick={this._themeSwitchCallback}
                    ><ChooseThemeIcon /></button>
                </div>

            </header>
        );

    }

    private _onThemeSwitch (e: React.MouseEvent<HTMLButtonElement>) {

        if (e) {
            e.preventDefault();
            e.stopPropagation();
        }

        const newValue = ThemeService.hasDarkMode() ? ColorScheme.LIGHT : ColorScheme.DARK;

        ThemeService.setColorScheme(newValue);

        if (this.props?.changeColorScheme) {
            this.props.changeColorScheme(newValue);
        }

    }

}
