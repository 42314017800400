import React from "react";
import DASH_JS, {MediaPlayerClass} from "dashjs";
import LogService from "../../nor/ts/LogService";
import "./VideoPlayer.scss";
import Loader from "../loader/Loader";

const LOG = LogService.createLogger('VideoPlayer');

export interface VideoPlayerState {

    readonly canPlay        : boolean;
    readonly manifestLoaded : boolean;
    readonly playbackPlaying : boolean;
    readonly playbackStarted : boolean;
    readonly isSeeking       : boolean;
    readonly streamInitialized       : boolean;

}

export interface VideoPlayerProps {
    readonly url      : string;
    readonly isMuted ?: boolean;
}

export class VideoPlayer extends React.Component<VideoPlayerProps, VideoPlayerState> {

    private readonly _playerRef: React.RefObject<HTMLVideoElement>;

    private _el     : HTMLVideoElement | null;
    private _player : MediaPlayerClass | undefined;

    public constructor(props: VideoPlayerProps) {

        super(props);

        this.state = {
            canPlay: false,
            manifestLoaded: false,
            playbackPlaying: false,
            playbackStarted: false,
            isSeeking: false,
            streamInitialized: false
        };

        this._playerRef = React.createRef<HTMLVideoElement>();
        this._el = null;

    }

    public componentDidMount() {

        this._updateDash();

    }

    public componentDidUpdate(prevProps: Readonly<VideoPlayerProps>, prevState: Readonly<VideoPlayerState>, snapshot?: any) {

        if (prevProps?.url !== this.props?.url) {
            this._updateDash();
        } else if (this._playerRef?.current !== this._el) {
            this._updateDash();
        }

        if (prevProps.isMuted !== this.props.isMuted) {
            this._updatePlayerMute();
        }

    }

    public render() {

        const { canPlay, manifestLoaded, playbackPlaying, playbackStarted, isSeeking, streamInitialized} = this.state;

        const loading : boolean = !(canPlay && manifestLoaded && playbackPlaying && playbackStarted && !isSeeking && streamInitialized);
        LOG.debug(`render: loading=${loading} <== canPlay=${canPlay}, manifestLoaded=${manifestLoaded}, playbackPlaying=${playbackPlaying}, playbackStarted=${playbackStarted}, isSeeking=${isSeeking}, streamInitialized=${streamInitialized}, `);

        return (
            <div className={"VideoPlayer VideoPlayer-" + (loading ? 'Loading' : 'Ready')}>
                <video
                    className="App-content-video"
                    ref={this._playerRef}
                    muted={true}
                    controls={false}
                    autoPlay={true}
                />
                {loading ? (
                    <div className="VideoPlayer-Loading"><Loader /></div>
                ) : null}
            </div>
        );
    }

    private _updateDash () {

        const el = this._playerRef?.current;

        if (el) {

            const url = this.props.url;

            this.setState({
                canPlay: false,
                manifestLoaded: false,
                playbackPlaying: false,
                playbackStarted: false,
                isSeeking: false,
                streamInitialized: false
            });

            const player = this._player = DASH_JS.MediaPlayer().create();

            player.initialize(el, url, true);

            player.updateSettings({
                debug: {
                    logLevel: 3
                },
                streaming: {

                    // manifestUpdateRetryInterval: 1000,
                    // lowLatencyEnabled: true,
                    // liveCatchup : {
                    //     minDrift: 0.2,
                    //     playbackRate: 0.5,
                    //     enabled: true
                    // },
                    // retryAttempts: {
                    //     MPD: 9999,
                    //     XLinkExpansion: 9999,
                    //     InitializationSegment: 9999,
                    //     IndexSegment: 9999,
                    //     MediaSegment: 9999,
                    //     BitstreamSwitchingSegment: 9999,
                    //     FragmentInfoSegment: 9999,
                    //     other: 9999
                    // },
                    // scheduling: {
                    //     defaultTimeout: 60000,
                    //     lowLatencyTimeout: 3000,
                    //     scheduleWhilePaused: true
                    // },
                    utcSynchronization: {
                        // useManifestDateHeaderTimeSource: true,
                        // backgroundAttempts: 2,
                        // timeBetweenSyncAttempts: 30000,
                        // maximumTimeBetweenSyncAttempts: 60000,
                        // minimumTimeBetweenSyncAttempts: 10000,
                        // timeBetweenSyncAttemptsAdjustmentFactor: 2,
                        // maximumAllowedDrift: 30000,
                        // enableBackgroundSyncAfterSegmentDownloadError: true,
                        defaultTimingSource: {
                            scheme: 'urn:mpeg:dash:utc:http-xsdate:2014',
                            value: 'https://time.akamai.com/?iso&ms'
                        }
                    }
                },
            });

            player.on(DASH_JS.MediaPlayer.events.CAN_PLAY, (e) => {
                LOG.info('MediaPlayer CAN_PLAY', e);
                this.setState({canPlay: true, isSeeking: player.isSeeking()});
            });

            player.on(DASH_JS.MediaPlayer.events.MANIFEST_LOADED, (e) => {
                LOG.info('MediaPlayer MANIFEST_LOADED', e);
                this.setState({manifestLoaded: true, isSeeking: player.isSeeking()});
            });

            player.on(DASH_JS.MediaPlayer.events.PLAYBACK_PLAYING, (e) => {
                LOG.info('MediaPlayer PLAYBACK_PLAYING', e);
                this.setState({playbackPlaying: true, isSeeking: player.isSeeking()});
            });

            player.on(DASH_JS.MediaPlayer.events.PLAYBACK_STARTED, (e) => {
                LOG.info('MediaPlayer PLAYBACK_STARTED', e);
                this.setState({playbackStarted: true, isSeeking: player.isSeeking()});
            });

            player.on(DASH_JS.MediaPlayer.events.PLAYBACK_SEEKING, (e) => {
                LOG.info('MediaPlayer PLAYBACK_SEEKING', e);
                this.setState({isSeeking: player.isSeeking()});
            });

            player.on(DASH_JS.MediaPlayer.events.STREAM_INITIALIZED, (e) => {
                LOG.info('MediaPlayer STREAM_INITIALIZED', e);
                this.setState({streamInitialized: true, isSeeking: player.isSeeking()});
            });

            player.on(DASH_JS.MediaPlayer.events.ERROR, (err) => {
                LOG.error('MediaPlayer ERROR', err);
            });

            player.on(DASH_JS.MediaPlayer.events.PLAYBACK_ERROR, (err) => {
                LOG.error('MediaPlayer PLAYBACK_ERROR', err);
            });

            player.on(DASH_JS.MediaPlayer.events.KEY_ERROR, (err) => {
                LOG.error('MediaPlayer KEY_ERROR', err);
            });

            player.on(DASH_JS.MediaPlayer.events.FRAGMENT_LOADING_ABANDONED, (err) => {
                LOG.error('MediaPlayer FRAGMENT_LOADING_ABANDONED', err);
            });

            player.on(DASH_JS.MediaPlayer.events.CONFORMANCE_VIOLATION, (err) => {
                LOG.error('MediaPlayer CONFORMANCE_VIOLATION', err);
            });

            player.on(DASH_JS.MediaPlayer.events.PLAYBACK_ENDED, (e) => {
                LOG.info('MediaPlayer PLAYBACK_ENDED', e);
            });

            player.on(DASH_JS.MediaPlayer.events.PLAYBACK_NOT_ALLOWED, (e) => {
                LOG.info('MediaPlayer PLAYBACK_NOT_ALLOWED', e);
            });

            player.on(DASH_JS.MediaPlayer.events.PLAYBACK_SEEKED, (e) => {
                LOG.info('MediaPlayer PLAYBACK_SEEKED', e);
                this.setState({isSeeking: player.isSeeking()});
            });

            player.on(DASH_JS.MediaPlayer.events.STREAM_INITIALIZING, (e) => {
                LOG.info('MediaPlayer STREAM_INITIALIZING',e);
            });

            player.on(DASH_JS.MediaPlayer.events.STREAM_DEACTIVATED, (e) => {
                LOG.info('MediaPlayer STREAM_DEACTIVATED', e);
            });

            player.on(DASH_JS.MediaPlayer.events.STREAM_ACTIVATED, (e) => {
                LOG.info('MediaPlayer STREAM_ACTIVATED', e);
            });

            player.on(DASH_JS.MediaPlayer.events.PLAYBACK_WAITING, (e) => {
                LOG.info('MediaPlayer PLAYBACK_WAITING', e);
            });

            player.on(DASH_JS.MediaPlayer.events.PLAYBACK_PAUSED, (e) => {
                LOG.info('MediaPlayer PLAYBACK_PAUSED', e);
            });

            player.on(DASH_JS.MediaPlayer.events.PLAYBACK_METADATA_LOADED, (e) => {
                LOG.info('MediaPlayer PLAYBACK_METADATA_LOADED', e);
            });

            player.on(DASH_JS.MediaPlayer.events.BUFFER_EMPTY, (e) => {
                LOG.info('MediaPlayer BUFFER_EMPTY', e);
            });

            this._el = el;

            this._updatePlayerMute();

            LOG.warn(`Video element initialized with url "${url}"`);

        } else {
            LOG.warn(`No video element detected.`);
        }

    }

    private _updatePlayerMute () {

        if (!this._player) {
            LOG.warn('Warning! No player to set mute');
            return;
        }

        const ourMute  : boolean = this.props?.isMuted ?? false;
        const liveMute : boolean = this._player.isMuted();

        if (liveMute !== ourMute) {
            this._player.setMute(ourMute);
            LOG.debug('Setting player mute as ', ourMute);
        } else {
            LOG.debug('Player mute was already same ', ourMute)
        }

    }

}
