import {isStreamResponseDTO, StreamResponseDTO} from "./StreamResponseDTO";
import {every, isArray} from "../../nor/ts/modules/lodash";

export interface StreamListResponseDTO {

    readonly streams: StreamResponseDTO[];

}

export function isStreamListResponseDTO (value : any) : value is StreamListResponseDTO {
    return (
        !!value
        && isArray(value?.streams)
        && every(value?.streams, isStreamResponseDTO)
    );
}
