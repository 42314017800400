import React from "react";
import {StreamListResponseDTO} from "../../types/StreamListResponseDTO";
import {StreamResponseDTO} from "../../types/StreamResponseDTO";
import map from "lodash/map";
import { Link } from "react-router-dom";
import {LiveHeader} from "../../header/LiveHeader";
import { Footer } from "../../footer/Footer";

export interface LiveListViewProps {

    readonly list     : StreamListResponseDTO;
    readonly darkMode : boolean;

}

export interface LiveListViewState {

}

export class LiveListView extends React.Component<LiveListViewProps, LiveListViewState> {

    public render() {

        const list : StreamResponseDTO[] = this.props.list.streams;

        const totalLiveCount : number = list?.length;

        return (
            <div className={'App App-List-View'  + (this.props.darkMode ? ' Dark-App' : ' Light-App')}>

                <LiveHeader
                />

                {totalLiveCount ? (

                    <section className="App-content">{map(
                        list,
                        (item : StreamResponseDTO) => {
                            return (
                                <article className="App-content-item" ref={`stream-${item.name}`}>
                                    <Link to={`/${item.name}`}>{item.name}</Link>
                                </article>
                            );
                        }
                    )}</section>

                ): (
                    <section className="App-content App-content-with-text">Esitettävää ei löytynyt.</section>
                )}

                <Footer />

            </div>
        );

    }

}
