import {isNumber, isString} from "../../nor/ts/modules/lodash";

export interface StreamResponseDTO {

    readonly name         : string;
    readonly playCount    : number;
    readonly liveCount    : number;
    readonly maxLiveCount : number;

}

export function isStreamResponseDTO (value : any) : value is StreamResponseDTO {
    return (
        !!value
        && isString(value?.name)
        && isNumber(value?.playCount)
        && isNumber(value?.liveCount)
        && isNumber(value?.maxLiveCount)
    );
}
