import React from "react";
import {StreamResponseDTO} from "../../types/StreamResponseDTO";
import {CHAT_URL, FEED_URL} from "../../../live-app-constants";
import LogService from "../../../nor/ts/LogService";
import { BackIcon, ChatLogo, MuteIcon, MuteIconOff } from "../../../assets/icon";
import {VideoPlayer} from "../../player/VideoPlayer";
import {LiveHeader, LiveHeaderChangeColorSchemeCallback} from "../../header/LiveHeader";
import {ColorScheme} from "../../../nor/ui/services/types/ColorScheme";
import ThemeService from "../../../nor/ui/services/ThemeService";
import { Footer } from "../../footer/Footer";

const LOG = LogService.createLogger('LiveView');

export interface LiveViewProps {

    readonly stream   : StreamResponseDTO;
    readonly darkMode : boolean;

}

export interface LiveViewState {

    readonly chatEnabled : boolean;
    readonly isMuted     : boolean;

}

export class LiveView extends React.Component<LiveViewProps, LiveViewState> {

    private readonly _toggleMuteCallback        : any;
    private readonly _toggleColorSchemeCallback : LiveHeaderChangeColorSchemeCallback;
    private readonly _chatRef                   : React.RefObject<HTMLIFrameElement>;

    public constructor (props: LiveViewProps) {

        super(props);

        this.state = {
            chatEnabled: false,
            isMuted: true
        };

        this._chatRef = React.createRef<HTMLIFrameElement>();

        this._toggleMuteCallback        = this._onToggleMute.bind(this);
        this._toggleColorSchemeCallback = this._onToggleColorScheme.bind(this);

    }

    public render() {

        const feedName : string = this.props.stream.name;
        const feedUrl  : string = FEED_URL(feedName);

        LOG.debug('url: ', feedUrl);

        return (
            <div className={'App LiveView-App'  + (this.props.darkMode ? ' Dark-App' : ' Light-App')}>

                <LiveHeader
                    icon={BackIcon}
                    title={feedName}
                    changeColorScheme={this._toggleColorSchemeCallback}
                >

                    <div className="App-chat-controls">

                        <button
                            className="chat-button"
                            onClick={() => this.setState((state) => ({chatEnabled: !state.chatEnabled}))} >
                            <ChatLogo />
                            {this.state.chatEnabled ? 'Sulje chat' : 'Avaa chat'}
                        </button>

                        <button
                            className="mute-button"
                            onClick={this._toggleMuteCallback}
                        >{ !this.state.isMuted ? (
                            <React.Fragment>
                                <MuteIcon />
                                Äänet pois
                            </React.Fragment>
                        ): (
                            <React.Fragment>
                                <MuteIconOff />
                                Äänet päälle
                            </React.Fragment>
                            )
                        }</button>

                    </div>

                </LiveHeader>

                <section className={"App-content App-content-chat-" + (this.state.chatEnabled ? 'enabled' : 'disabled')}>

                    <div className="App-content-main">

                        <VideoPlayer
                            url={feedUrl}
                            isMuted={this.state.isMuted}
                        />

                    </div>

                    <div className={"App-chat App-chat-" + (this.state.chatEnabled ? 'enabled' : 'disabled')}>

                        <iframe className="App-chat"
                                ref={this._chatRef}
                                title={'chat'}
                                src={CHAT_URL}
                                width="100%"
                                height="100%"
                                scrolling="none"
                                frameBorder={0}
                                marginWidth={0}
                                marginHeight={0}
                                allowFullScreen={true}
                        />

                    </div>

                </section>

                <Footer />

            </div>
        );

    }

    private _onToggleMute () {

        LOG.debug('Toggling mute from ', this.state.isMuted);

        this.setState( (state) => {
            const isMuted = !state?.isMuted;
            LOG.debug('Setting mute as ', isMuted);
            return {isMuted};
        });

    }

    private _onToggleColorScheme (value: ColorScheme) {

        const contentWindow = this._chatRef?.current?.contentWindow;
        if (contentWindow) {
            ThemeService.setRemoteColorScheme(value, contentWindow);
        } else {
            LOG.warn(`Warning! Could not set chat iframe color scheme without the content window`);
        }

    }

}
