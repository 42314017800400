import CustomerLogo from "../../assets/Audio-ANTZA.png";
import { AD_TITLE, AD_URL } from "../../live-app-constants";
import { ReactComponent as SendanorLogo } from "../../assets/sendanor.svg";
import React from "react";
import PalvelinkauppaLogo from "../../assets/palvelinkauppa.png";
import "./Footer.scss";

export function Footer () {

    return (
        <footer className="App-footer">

            <div className="App-content-siteinfo">
                <a href={"https://www.facebook.com/Audioantza-112026673820183"}
                   target={"_blank"}
                   rel="noreferrer"
                >
                    <span className="App-content-siteinfo-text">
                    Tykkää myös<br />
                    Facebookissa:
                    </span>
                    <img src={CustomerLogo} alt={"Audioantza"} />
                </a>
            </div>

            <iframe className="App-content-hog-iframe"
                    title={AD_TITLE}
                    src={AD_URL}
                    width="800"
                    height="100"
                    scrolling="none"
                    frameBorder="0" />

            <div className="App-content-logos">

                <span className={"title-text"}>Suoratoistosta vastaa</span>

                <div className={"logo-container sendanor-logo"}>
                    <a href={"https://www.sendanor.fi"}
                       target={"_blank"}
                       className={"logo-link"}
                       rel="noreferrer"
                    ><SendanorLogo /></a>
                </div>

                <span className={"split-text"}>…ja…</span>

                <div className={"logo-container palvelinkauppa-logo"}>
                    <a href={"https://www.palvelinkauppa.fi"}
                       target={"_blank"}
                       className={"logo-link"}
                       rel="noreferrer"
                    ><img src={PalvelinkauppaLogo}
                          className={"logo-image"}
                          alt={"Palvelinkauppa.fi"} /></a>
                </div>

            </div>

        </footer>
    );

}
